import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignUp } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import useApiData from "utils/hooks/useApiData";
import { Notification, toast } from "components/ui";
import { SESSION_EXPIRY_MINUTES } from "constants/api.constant";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { getMenuList, getList } = useApiData();

  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      let resp = await apiSignIn(values);

      if (resp.data.status == 'success') {
        const { token } = resp.data;
        const { role_name } = resp.data.data;

        let menuData = await getMenuList(
          `academics/master/page_info/page_info.php?mode=list&role_id=${resp.data.data.role_id}`,
          token
        );

        resp.data.data.menu_list = [];

        if (menuData) {
          resp.data.data.menu_list = menuData;
        }

        let sysData = await getMenuList(`academics/home.php?mode=sys-info`, token);

        if (sysData.length > 0) {
          let currentSession = {
            "session_year": "",
            "session_type": ""
          }

          for (let item of sysData) {
            if (item.short_name == "CURR_ACADEMIC_SESSION_YEAR") {
              currentSession.session_year = item.value;
            }
            if (item.short_name == "CURR_ACADEMIC_SESSION_TYPE") {
              currentSession.session_type = item.value;
            }
          }
          resp.data.data.current_session = currentSession;
        }

        let portalAccess = [
          "academics",
          "students",
          "mentor",
          "placement",
          "hostel",
        ];

        if (portalAccess) {
          resp.data.data.portal_access = portalAccess;
        }

        localStorage.setItem('loggedin_user_role', resp.data.data.role_code);
        localStorage.tabCount = 1;

        let SESSION_EXPIRY_TIME = (Date.now() + SESSION_EXPIRY_MINUTES * 60 * 1000);
        localStorage.setItem('session_expiry_time', SESSION_EXPIRY_TIME.toString());

        dispatch(onSignInSuccess(token));
        if (resp.data.data) {
          dispatch(
            setUser(
              resp.data.data || {
                avatar: "",
                userName: "Anonymous",
                authority: [role_name],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        openNotification(
          "success",
          "You have successfully logged in"
        );
        return {
          status: "success",
          message: "",
        };
      } else {
        return {
          status: "failed",
          message: resp.data.message,
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: "Unable to login at the moment",
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await apiSignUp(values);
      if (resp.data) {
        const { token } = resp.data;
        dispatch(onSignInSuccess(token));
        if (resp.data.user) {
          dispatch(
            setUser(
              resp.data.user || {
                avatar: "",
                userName: "Anonymous",
                authority: ["USER"],
                email: "",
              }
            )
          );
        }
        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const openNotification = (type, message) => {
    toast.push(
      <Notification
        title={type.charAt(0).toUpperCase() + type.slice(1)}
        type={type}
      >
        {message}
      </Notification>
    );
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    localStorage.removeItem("loggedin_user_role");
    localStorage.removeItem("session_expiry_time");
    localStorage.removeItem("teacher_activity_list");
    localStorage.removeItem("admin");
    navigate(appConfig.unAuthenticatedEntryPath);
    openNotification(
      "success",
      "You have successfully logged out"
    );
  };

  const signOut = async () => {
    // await apiSignOut();
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
  };
}

export default useAuth;
