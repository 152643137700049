import axios from "axios";
import { useState } from "react";
import { BASE_URL, CI_BASE_URL, L_BASE_URL, TOKEN_TYPE } from "constants/api.constant";
import { useSelector, useDispatch } from "react-redux";
import { Notification, toast } from "components/ui";
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { setUser, initialState } from "store/auth/userSlice";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import packageJson from "../../../package.json";

const useApiData = () => {
  const [tableData, setTableData] = useState([]);
  const [dropdownList, setDropdownList] = useState([]);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { session } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const config = {
    headers: {
      "Content-Type": "application/json",
      // "Content-Type": "multipart/form-data",
      // 'Access-Control-Allow-Origin': '*',
      Authorization: `${TOKEN_TYPE}${session?.token}`,
    },
  };
  const fileConfig = {
    headers: {
      "Content-Type": "application/json",
      // "Content-Type": "multipart/form-data",
      Authorization: `${TOKEN_TYPE}${session?.token}`,
    },
  };

  const openNotification = (type, message) => {
    toast.push(
      <Notification
        title={type.charAt(0).toUpperCase() + type.slice(1)}
        type={type}
      >
        {message}
      </Notification>
    );
  };

  const fetchData = async (url) => {
    setLoading(true);
    setError("");
    try {
      let { data } = await axios.get(`${BASE_URL}/${url}`, config);
      if (data?.data?.length > 0) {
        for (let i in data?.data) {
          data.data[i].sl_no = parseInt(i) + 1;
        }
      }
      setTableData(data?.data ? data?.data : []);
      setLoading(false);
    } catch (error) {
      setTableData([]);
      setError(error?.response?.data?.message);
      setLoading(false);
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      } else {
        openNotification(
          error?.response?.data?.status,
          error?.response?.data?.message
        );
      }
    }
  };
  const getDropDownList = async (url) => {
    setLoading(true);
    setError("");
    try {
      const { data } = await axios.get(`${BASE_URL}/${url}`, config);
      setDropdownList(data?.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      }
    }
  };

  const postData = async (url, input) => {
    setLoading(true);
    setError("");
    try {
      const { data } = await axios.post(`${BASE_URL}/${url}`, input, config);
      setStatus(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      }
    }
  };
  const modifyRow = async (url, input) => {
    setLoading(true);
    setError("");
    try {
      const { data } = await axios.post(`${BASE_URL}/${url}`, input, config);
      setStatus(data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      }
    }
  };

  const getList = async (url) => {
    try {
      let { data } = await axios.get(`${BASE_URL}/${url}`, config);
      return data ? data?.data : [];
    } catch (error) {
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      }
    }
  };

  const getCourseList = async (url) => {
    try {
      let { data } = await axios.get(`${BASE_URL}/${url}`, config);
      if (url === 'academics/home.php?mode=course') {
        if (Array.isArray(data.data) && data.data.length > 0) {
          for (let i in data.data) {
            let startIndex = data.data[i].course_name.indexOf('[');
            let endIndex = data.data[i].course_name.indexOf(']');
            let courseName = data.data[i].course_name.slice(startIndex + 1, endIndex);
            let year = data.data[i].course_name.slice(startIndex - 5, startIndex);
            data.data[i].course_name = `${courseName.trim()} - ${year.trim()}`;
          }
          return data.data;
        } else {
          return data ? data?.data : [];
        }
      } else {
        return data ? data?.data : [];
      }
    } catch (error) {
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      }
    }
  };

  const getShortCourseName = async (courseName) => {
    let startIndex = courseName.indexOf('[');
    let endIndex = courseName.indexOf(']');
    let cName = courseName.slice(startIndex + 1, endIndex);
    let year = courseName.slice(startIndex - 5, startIndex);
    let finalCourseName = `${cName.trim()} - ${year.trim()}`;
    return finalCourseName;
  }

  const postResponse = async (url, input) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/${url}`, input, config);
      return data ? data : [];
    } catch (error) {
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      } else {
        return { message: error?.response?.data?.message, status: "danger" };
      }
    }
  };

  const getResponse = async (url) => {
    try {
      const { data } = await axios.get(`${BASE_URL}/${url}`, config);
      return data;
    } catch (error) {
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      } else {
        return { message: error?.response?.data?.message, status: "danger" };
      }
    }
  };

  const formDataPost = async (url, input) => {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/${url}`,
        input,
        fileConfig
      );
      return data;
    } catch (error) {
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      } else {
        return { message: error?.response?.data?.message, status: "danger" };
      }
    }
  };

  const fetchDataList = async (url) => {
    setLoading(true);
    setError("");
    try {
      const { data } = await axios.get(`${BASE_URL}/${url}`, config);
      setLoading(false);
      return data?.data || data;
    } catch (error) {
      setError(error?.message);
      setLoading(false);
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      }
      return [];
    }
  };

  const postDataList = async (url) => {
    setLoading(true);
    setError("");
    try {
      const { data } = await axios.get(`${BASE_URL}/${url}`, config);
      setLoading(false);
      return data;
    } catch (error) {
      setError(error?.message);
      setLoading(false); let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      }
      return [];
    }
  };

  const getMenuList = async (url, token) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${TOKEN_TYPE}${token}`,
        },
      };

      const { data } = await axios.get(`${BASE_URL}/${url}`, config);
      return data?.data || data;
    } catch (error) {
    }
  };

  // For CI API call 
  const postDataCI = async (url, input) => {
    try {
      const { data } = await axios.post(`${CI_BASE_URL}/${url}`, input, config);
      return data ? data : [];
    } catch (error) {
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      }
    }
  };

  const getListCI = async (url) => {
    try {
      const { data } = await axios.get(`${CI_BASE_URL}/${url}`, config);
      return data ? data?.data : [];
    } catch (error) {
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      }
    }
  };

  const getResponseCI = async (url) => {
    try {
      const { data } = await axios.get(`${CI_BASE_URL}/${url}`, config);
      return data;
    } catch (error) {
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      } else {
        return { message: error?.response?.data?.message, status: "danger" };
      }
    }
  };

  const postResponseCI = async (url, input) => {
    try {
      const { data } = await axios.post(`${CI_BASE_URL}/${url}`, input, config);
      return data ? data : [];
    } catch (error) {
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      } else {
        return { message: error?.response?.data?.message, status: "danger" };
      }
    }
  };

  const getDataCI = async (url) => {
    try {
      const { data } = await axios.get(`${CI_BASE_URL}/${url}`, config);
      return data ? data : [];
    } catch (error) {
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      } else {
        return { message: error?.response?.data?.message, status: "danger" };
      }
    }
  };

  const fetchDataListCI = async (url) => {
    setLoading(true);
    setError("");
    try {
      const { data } = await axios.get(`${CI_BASE_URL}/${url}`, config);
      setLoading(false);
      return data?.data || data;
    } catch (error) {
      setError(error?.message);
      setLoading(false);
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      }
      return [];
    }
  };

  const postDataL = async (url, input) => {
    try {
      const { data } = await axios.post(`${L_BASE_URL}/${url}`, input, config);
      return data ? data : [];
    } catch (error) {
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      } else {
        return { message: error?.response?.data?.message, status: "danger" };
      }
    }
  };

  const getDataL = async (url) => {
    try {
      const { data } = await axios.get(`${L_BASE_URL}/${url}`, config);
      return data?.data || data;
    } catch (error) {
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      } else {
        return { message: error?.response?.data?.message, status: "danger" };
      }
    }
  };

  const getResponseDataL = async (url) => {
    try {
      const { data } = await axios.get(`${L_BASE_URL}/${url}`, config);
      return data;
    } catch (error) {
      let errorStatus = error.response.status;
      if (errorStatus == 401) {
        sessionExpire();
      } else {
        return { message: error?.response?.data?.message, status: "danger" };
      }
    }
  };

  const getRoleBasedAllOption = async (optionName) => {
    let value = await getList("academics/home.php?mode=sys-info");
    let userRole = user.role_code;
    let roleArr = [];

    if (value.length > 0) {
      for (let item of value) {
        if (item.short_name == "All_ALLOW_ROLE_CODE") {
          roleArr = [...roleArr, ...item.value.split('|')];
        }
      }
    }
    let roleFlag = roleArr.includes(userRole);

    if (roleFlag) {
      return [{ label: `All ${optionName ? optionName : ""}`, value: "-1" }]
    } else {
      return []
    }
  };

  let sessionExpire = () => {
    if (location.pathname != '/sign-in') {
      openNotification(
        "error",
        "Your session has expired"
      );
    }
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    localStorage.removeItem("loggedin_user_role");
    localStorage.removeItem("session_expiry_time");
    localStorage.removeItem("teacher_activity_list");
    localStorage.removeItem("admin");
    localStorage.setItem('version', packageJson.version);
    navigate("/sign-in");
  }

  return {
    tableData,
    loading,
    error,
    fetchData,
    postData,
    getDropDownList,
    dropdownList,
    modifyRow,
    status,
    getList,
    getResponse,
    postResponse,
    getMenuList,
    fetchDataList,
    postDataList,
    formDataPost,
    getCourseList,
    postDataCI,
    getListCI,
    getDataCI,
    postResponseCI,
    fetchDataListCI,
    getResponseCI,
    postDataL,
    getDataL,
    getResponseDataL,
    getShortCourseName,
    getRoleBasedAllOption
  };
};

export default useApiData;
